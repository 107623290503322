<ng-template #content let-offcanvas>
  <div class="offcanvas-header bg-dark text-light">
    <h4 class="mb-0 fw-semibold icon-conserve">
      CONSERVE
    </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <div class="offcanvas-body">
    <ul class="nav nav-pills flex-column h-100">
      @if (authService.currentLoginUser) {
        <a class="nav-item nav-link bi-house" routerLinkActive="active" routerLink="/audits">
          Home
        </a>
      }
      <div class="flex-grow-1"></div>
      <div ngbDropdown class="d-inline-block">
        <button class="nav-link bi-moon-stars" ngbDropdownToggle>
          Theme
        </button>
        <div ngbDropdownMenu aria-labelledby="accountDropdown" class="dropdown-menu-end">
          <h6 class="dropdown-header">Theme</h6>
          @for (theme of themes; track theme) {
            <button
              ngbDropdownItem
              class="dropdown-item-check {{ theme.value === selectedTheme ? theme.selectedIcon : theme.icon }}"
              [disabled]="theme.value === selectedTheme"
              (click)="selectTheme(theme.value)"
            >
              {{ theme.name }}
            </button>
          }
        </div>
      </div>
      @if (authService.currentLoginUser) {
        <a class="nav-item nav-link bi-gear" routerLinkActive="active" routerLink="/auth/change-password">
          Change Password
        </a>
        <button class="nav-link nav-item text-start text-danger bi-box-arrow-right" routerLinkActive="active" (click)="logout()">
          Log Out
        </button>
        <hr/>
        <div class="d-flex align-items-center">
          <div class="bi-person-circle fs-1 mx-3"></div>
          <div>
            <h5 class="mb-0 fw-bold">{{ authService.currentLoginUser.userName }}</h5>
            <h6 class="mb-0 fw-light">{{ authService.currentLoginUser.role.role | titlecase }}</h6>
          </div>
        </div>
      } @else {
        <a class="nav-item nav-link bi-person" routerLinkActive="active" routerLink="/auth/login">
          Log In
        </a>
        <a class="nav-item nav-link bi-person-add" routerLinkActive="active" routerLink="/auth/signup">
          Sign Up
        </a>
      }
    </ul>
  </div>
</ng-template>
<nav class="navbar navbar-collapse navbar-dark bg-dark">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" (click)="offcanvas.open(content)">
      &#9776;
    </button>
    <!-- TODO: Show breadcrumbs / current page title -->
  </div>
</nav>
